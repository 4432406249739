<template>
  <div>
    <BaseFrontOfficeTitle v-text="$t('wallet.signup.title')" />
    <BaseFrontOfficeDivider type="grey" class="my-4" />
    <v-form>
      <BaseFrontOfficeTextField
        v-model="form.email"
        :label="`${$tc('utils.email', 1)}*`"
        :error-messages="emailError"
        placeholder="exemple@mail.com"
      />
      <BaseFrontOfficeTextField
        v-model="form.password"
        :label="`${$t('account.password')}*`"
        :type="show.password ? 'text' : 'password'"
        :error-messages="passwordErrors"
        :append-icon="showPasswordIcon"
        placeholder="•••••••••"
        :autofocus="$route.params.invitedUserEmail ? true : false"
        @click:append="show.password = !show.password"
      >
        <template #tooltip>
          <v-tooltip v-model="open" top>
            <template #activator="{ on }">
              <BaseFrontOfficeIcon
                color="walletPrimary"
                icon="$mdiInformation"
                v-on="on"
              />
            </template>
            <ul>
              <li
                v-for="(warning, index) in passwordWarnings"
                :key="`${warning.id}-${index}`"
                v-text="warning.value"
              />
            </ul>
          </v-tooltip>
        </template>
      </BaseFrontOfficeTextField>
      <BaseFrontOfficeTextField
        v-model="form.confirmPassword"
        :label="`${$t('account.confirmPassword')}*`"
        :type="show.confirmPassword ? 'text' : 'password'"
        :error-messages="confirmPasswordErrors"
        :append-icon="showConfirmPasswordIcon"
        placeholder="•••••••••"
        @click:append="show.confirmPassword = !show.confirmPassword"
      />
      <BaseFrontOfficeCheckbox
        v-model="form.confirmCGU"
        name="confirmCGU"
        @change="$v.form.confirmCGU.$touch()"
      >
        <template #label>
          <i18n path="wallet.signup.conditionAgreement">
            <template #cgu>
              <BaseFrontOfficeHyperlink
                url="https://www.devngo.fr/conditions-generales-dutilisation/"
                target="_blank"
                @click.stop
              >
                {{ $t("wallet.signup.consultCGU") }}
              </BaseFrontOfficeHyperlink>
            </template>
          </i18n>
        </template>
      </BaseFrontOfficeCheckbox>
      <BaseFrontOfficeAlert v-if="submitError" type="error">
        {{ $t("auth.reset.registerError1") }}
        <br />
        <i18n path="auth.reset.registerError2">
          <template #message>
            <BaseFrontOfficeHyperlink
              v-if="$route.fullPath.indexOf('/wallet') != -1"
              :to="{ name: 'WalletPasswordReset' }"
            >
              {{ $t("auth.reset.registerErrorMessage") }}
            </BaseFrontOfficeHyperlink>
            <BaseFrontOfficeHyperlink
              v-else
              :to="{
                name: 'PasswordReset',
              }"
            >
              {{ $t("auth.reset.registerErrorMessage") }}
            </BaseFrontOfficeHyperlink>
          </template>
        </i18n>
      </BaseFrontOfficeAlert>
      <div class="d-flex flex-column">
        <BaseFrontOfficeButton
          class="ml-auto"
          icon="$mdiCheck"
          :loading="isLoading"
          @click="submit()"
        >
          {{ $t("wallet.signup.submit") }}
        </BaseFrontOfficeButton>
      </div>
      <BaseDivider grey class="my-4" />
      <div class="d-flex justify-center my-4">
        <router-link
          :to="{ name: 'Login' }"
          class="link_signup"
          v-text="$t('wallet.signup.hasAnAccount')"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { getToken } from "@/core/Api/cosmoAxiosInstance";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  sameAs,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import { WelcomeActionStatus } from "@/core/Utils/types.utils";
import RecordApi from "@/modules/Wallet/Services/record.api";

export default {
  name: "WalletRegister",
  mixins: [validationMixin],
  props: {
    accessCampaign: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      open: false,
      isLoading: false,
      submitError: false,
      form: {
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        email: "",
        confirmCGU: false,
      },
      show: {
        password: false,
        confirmPassword: false,
      },
      passwordWarnings: [
        {
          value: this.$t(
            "account.editForm.warning.passwordDifferentFromPersonalData"
          ),
          id: 0,
        },
        { value: this.$t("account.editForm.errors.newPasswordLength"), id: 1 },
        {
          value: this.$t("account.editForm.warning.passwordShouldBeUnique"),
          id: 2,
        },
        {
          value: this.$t("account.editForm.warning.passwordNotAllNumeric"),
          id: 3,
        },
      ],
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(255),
      },
      confirmPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(255),
        sameAsPassword: sameAs("password"),
      },
      confirmCGU: {
        sameAs: sameAs(() => true),
      },
    },
  },
  computed: {
    // password icon
    showPasswordIcon() {
      if (this.form.password === "") return "";
      return this.show.password ? "$mdiEye" : "$mdiEyeOff";
    },
    showConfirmPasswordIcon() {
      if (this.form.confirmPassword === "") return "";
      return this.show.confirmPassword ? "$mdiEye" : "$mdiEyeOff";
    },
    //#region Errors
    emailError() {
      if (!this.$v.form.email.$dirty) return "";
      if (!this.$v.form.email.required) {
        return this.$t("wallet.signup.errors.emailRequired");
      }
      if (!this.$v.form.email.email) {
        return this.$t("folder.askInformationForm.validation.emailInvalid");
      }
      return "";
    },
    passwordErrors() {
      if (!this.$v.form.password.$dirty) return [];

      let errors = [];
      if (!this.$v.form.password.required)
        errors = [...errors, this.$t("wallet.signup.errors.passwordRequired")];

      if (!this.$v.form.password.minLength)
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordLength"),
        ];
      return errors;
    },
    confirmPasswordErrors() {
      if (!this.$v.form.confirmPassword.$dirty) return [];

      let errors = [];
      if (!this.$v.form.confirmPassword.required)
        errors = [
          ...errors,
          this.$t("wallet.signup.errors.confirmPasswordRequired"),
        ];

      if (!this.$v.form.confirmPassword.minLength)
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordLength"),
        ];

      if (!this.$v.form.confirmPassword.sameAsPassword) {
        errors = [
          ...errors,
          this.$t("client.account.editForm.errors.newPasswordConfirm"),
        ];
      }
      return errors;
    },
    //#endregion
  },
  created() {
    if (this.$route.params.invitedUserEmail) {
      this.form.email = this.$route.params.invitedUserEmail;
    } else if (this.$route.query?.email) {
      this.form.email = this.$route.query.email;
    }
  },
  methods: {
    async createRecord() {
      if (this.$route.params.recordId) {
        // we dont create a record if pk record id
        return this.$router.push({
          name: "WalletCourse",
          params: {
            recordId: this.$route.params.recordId,
          },
        });
      }
      const res = await RecordApi.postRecord(
        this.$route.params.accessCampaign.campaign.slug
      );
      const record = res.data;
      this.$router.push({
        name: "WalletCourse",
        params: {
          recordId: record.id,
        },
      });
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }
      this.isLoading = true;
      this.submitError = false;
      const payload = {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        email: this.form.email,
        accept_terms: this.form.confirmCGU,
        password1: this.form.password,
        password2: this.form.confirmPassword,
      };
      try {
        await this.$store.dispatch("account/userRegister", payload);
      } catch (error) {
        const { status, data } = error.response;
        if (status === 400 && data.code === "user-already-exists") {
          this.submitError = true;
          this.isLoading = false;
        } else {
          this.$store.dispatch("snackbar/active", {
            message: error.response.statusText,
            type: "ERROR",
          });
        }
        return;
      }

      await getToken(this.form.email, this.form.password);
      this.$store.commit("core/SET_HAS_LOGGED_NORMALY", true);
      await this.$store.dispatch("account/fetchUser");

      // SIGN UP FROM CAMPAIGN LINK
      if (this.$route.params.accessCampaign) {
        // We need to get the latest access campaign
        const res = await this.$store.dispatch(
          "walletCourse/getAccessCampaign",
          {
            campaignSlug: this.$route.params.accessCampaign.campaign.slug,
            recordId: this.$route.params.recordId,
          }
        );

        switch (res.data.action) {
          case WelcomeActionStatus.CreateRecord:
            await this.createRecord();
            return;
          case WelcomeActionStatus.Answer:
            this.$router.push({
              name: "WalletCourse",
              params: {
                recordId: this.$route.params.recordId,
              },
            });
            return;
        }
      }

      if (this.$route.query.next) {
        this.$router.push(this.$route.query.next);
        return;
      }
      if (/^\/auth/.test(this.$route.fullPath)) {
        this.$router.push({ name: "dashboard" });
        return;
      }
      this.$router.push({ name: "WalletHome" });
    },
  },
};
</script>

<style scoped>
.link_signup {
  text-decoration: none;
}
.link_signup:hover {
  transition: all 0.75s ease;
  color: var(--v-walletAccent-base);
  text-decoration: underline;
}
</style>
